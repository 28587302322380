<template>
  <div>
    <div style="padding: 50px 0 50px 0;background-color: rgb(11, 11, 11);min-width:100vw;display: flex;min-height: 100vh;flex-direction: column; justify-content: center;align-items: center;">
      <h1 style="color:white;margin: 0 0 20px 0;">Status Monitor</h1>
      <h2 style="color:white;margin: 0 0 20px 0;">Aktualna godzina: {{ timestamp }}</h2>
      <h2 style="color:white;margin: 0 0 30px 0">Ostatnia aktualizacja: {{ timestampNow }}</h2>
      <div v-for="(status, i) in statuses" :key="i" style="width:400px;height:80px;background-color: white;display:flex;flex-direction: row;justify-content: space-between;padding: 20px;align-items: center;border-bottom:1px solid gray">
        <a :href="`${urls[i]}`" style="font-size:20px;">{{ urls[i] }}</a>
        <div v-if="status=='ok'" style="width:50px;height:50px;background-color: green;border-radius: 25px;"></div>
        <div v-else style="width:50px;height:50px;background-color: red;border-radius: 25px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'monitor-main',
  data() {
    return {
      timestamp: "",
      timestampNow: "",
      urls: [
        'https://dipit.dev/',
        'https://api.dipit.dev/',
        'https://instytutecho.com/',
        'https://api.instytutecho.com/',
        'https://safety.css.usermd.net',
        'https://contract.css.usermd.net',
        'https://latarnikchoczewo.pl/',
        'https://api.latarnikchoczewo.pl/',
        'https://panda-rumia.pl/',
        'https://api.panda-rumia.pl/',
      ],
      statuses: []
    }
  },
  mounted() {
    let interval = 1000 * 60;
    this.getData()
    setInterval(() => {
      this.getData()
    }, interval)
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = time + ' ' + date;
      this.timestamp = dateTime;
    },
    async getData() {
      this.statuses = []
      for (let i = 0; i < this.urls.length; i++) {
        try {
          const response = await axios.get(this.urls[i]);
          if (response.status === 200) {
            this.statuses.push('ok');
          } else {
            this.statuses.push('not_ok');
          }
        } catch (error) {
          this.statuses.push('not_ok');
        }
      }
      const today = new Date();
      const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = time + ' ' + date;
      this.timestampNow = dateTime;
    }
  }
}
</script>